<template>
  <b-card
    no-body
    class="card-apply-job"
    @click="viewDetailed()"
    style="cursor: pointer"
  >
    <b-card-header class="">
      <b-media no-body>
        <b-media-aside class="mr-1">
          <b-avatar size="42"> </b-avatar>
        </b-media-aside>
        <b-media-body class="my-auto">
          <h4 class="my-auto">{{ interviews.client.name }}</h4>
          <small v-if="interviews.status === 'Interview Requested'">{{
            interviews.assignment.title
          }}</small>
        </b-media-body>
      </b-media>
    </b-card-header>
    <b-card-body>
      <b-media no-body class="">
        <b-media-aside class="mr-1">
          <b-media-aside class="mr-2">
            <b-avatar rounded variant="light-primary" size="34">
              <feather-icon icon="CalendarIcon" size="18" />
            </b-avatar>
          </b-media-aside>
          <b-media-body class="date">
            <div v-if="interviews.status === 'Candidate Invited'">
              <h6 class="mb-0">Invites you for</h6>
              <small>{{ interviews.assignment.title }}</small>
            </div>
            <div v-if="interviews.status === 'Interview Requested'">
              <h6 class="mb-0">Interview Scheduled</h6>
              <small
                >On :
                <strong>
                  {{ interviews.interview.start_date }}
                </strong>
                At :
                <strong>
                  {{ interviews.interview.start_time }}
                </strong>
              </small>
            </div>
          </b-media-body>
        </b-media-aside>
      </b-media>

      <!-- <div class="d-flex mt-0 justify-content-between">
        <b-button
          id="show-btn"
          v-b-modal.modal-reschedule
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
         
        >
          View
        </b-button>
      </div> -->
    </b-card-body>
  </b-card>
</template>
<script>
import {
  BAvatar,
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  BMedia,
  BMediaAside,
  BMediaBody,
} from "bootstrap-vue"

import Ripple from "vue-ripple-directive"
// import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"

export default {
  components: {
    BCard,
    BButton,
    BCardHeader,
    BMedia,
    BMediaAside,
    BAvatar,
    BCardBody,
    BMediaBody,
  },
  directives: {
    Ripple,
  },
  props: {
    interviews: {
      type: Object,
      required: true,
    },
  },
  mounted() {},
  methods: {
    viewDetailed() {
      this.$router.push(`/assignment-proposal/${this.interviews.assignment_id}`)

      // this.$router.push({
      //   path: "/assignment-proposal",
      //   params: { id: this.interviews.id },
      // })
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.date {
  height: 50px;
}
</style>
