<template>
  <div>
    <div class="mb-0" v-if="upcomingInvites.length !== 0">
      <h2 class="mb-2 d-block">Upcoming Invites</h2>
      <b-row>
        <b-col
          v-for="(candidate, i) in upcomingInvites"
          :key="i"
          lg="4"
          md="6"
          sm="6"
        >
          <CandidateInterviewUpcoming :interviews="candidate" />
        </b-col>
      </b-row>
    </div>
    <div class="mb-0" v-if="upcomingInterviews.length !== 0">
      <h2 class="mb-2 d-block">Upcoming Interviews</h2>
      <b-row>
        <b-col
          v-for="(candidate, i) in upcomingInterviews"
          :key="i"
          lg="4"
          md="6"
          sm="6"
        >
          <CandidateInterviewUpcoming :interviews="candidate" />
        </b-col>
      </b-row>
    </div>
    <div>
      <div>
        <h2 class="mb-2 d-block">Assignments</h2>
        <b-row class="match-height">
          <b-col
            v-for="(assignment, i) in assignments"
            :key="i"
            lg="3"
            md="4"
            sm="6"
            @click="handleActiveAssignment(assignment.statisticTitle)"
          >
            <Assignment
              :icon="assignment.icon"
              :statistic="assignment.statistic"
              :statistic-title="assignment.statisticTitle"
              :color="assignment.color"
              :select-assignment="selectAssignment"
            />
          </b-col>
        </b-row>
      </div>
      <assignments-table
        @custom-event-getAssignment="reloadPage"
        :assignments="items"
        v-if="items"
      />
    </div>
  </div>
</template>

<script>
import axios from "@/libs/axios"
import { BCol, BRow } from "bootstrap-vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import errorResponse from "@/libs/axiosError"
import Assignment from "./Assignment.vue"
import AssignmentsTable from "./AssignmentsTable.vue"
import CandidateInterviewUpcoming from "./CandidateInterviewUpcoming.vue"

export default {
  components: {
    BRow,
    BCol,
    Assignment,
    CandidateInterviewUpcoming,
    AssignmentsTable,
  },
  data: () => ({
    selectAssignment: "All Project",
    assignments: [],
    upcomingInvites: [],
    upcomingInterviews: [],
    message: "",
    perPage: 5,
    pageOptions: [3, 5, 10],
    totalRows: 1,
    currentPage: 1,
    sortBy: "",
    sortDesc: false,
    sortDirection: "asc",
    filter: null,
    filterOn: [],
    infoModal: {
      id: "info-modal",
      title: "",
      content: "",
    },
    fields: [
      { key: "title", label: "Title", sortable: true },
      // {
      //   key: 'id',
      //   label: 'Id',
      // },
      {
        key: "avatar",
        label: "",
      },
      { key: "full_name", label: "Client", sortable: true },
      // { key: 'post', label: 'Post', sortable: true },
      // 'email',
      // 'city',
      { key: "start_date", label: "Added On", sortable: true },
      // 'salary',
      // 'age',
      // 'experience',
      {
        key: "status",
        label: "Status",
        sortable: true,
        filterOn: true,
      },
      { key: "actions", label: "Actions" },
    ],
    items: [],
    status: [
      {
        1: "Active",
        2: "Proposal",
        3: "Interview Stage",
        4: "Shortlisted",
        5: "Waiting for Payment",
        6: "Shortlisted",
        7: "Expired",
        8: "Completed",
      },
      {
        1: "light-primary",
        2: "light-success",
        3: "light-danger",
        4: "light-warning",
        5: "light-info",
        6: "light-success",
        7: "light-dark",
        8: "light-danger",
      },
    ],
  }),
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    this.getCandidateInvites()
    this.getCandidateInterviews()
    this.getAssignmentsCount()
    this.getAssignments("filter[status]=All Project")
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  methods: {
    reloadPage() {
      this.getAssignmentsCount()
      this.getAssignments("filter[status]=All Project")
    },
    handleActiveAssignment(statisticTitle) {
      if (this.selectAssignment !== statisticTitle) {
        this.selectAssignment = statisticTitle
        if (this.selectAssignment === "Active Assignments") {
          this.getAssignments("filter[status]=Active Assignments")
        }
        if (this.selectAssignment === "Assignments Proposal") {
          this.getAssignments("filter[status]=Assignments Proposal")
        }
        if (this.selectAssignment === "Interview Stage") {
          this.getAssignments("filter[status]=Interview Stage")
        }
        if (this.selectAssignment === "Shortlisted for") {
          this.getAssignments("filter[status]=Shortlisted for")
        }
        if (this.selectAssignment === "Waiting for payment") {
          this.getAssignments("filter[status]=Waiting for payment")
        }
        if (this.selectAssignment === "Completed") {
          this.getAssignments("filter[status]=Completed")
        }
        if (this.selectAssignment === "All Project") {
          this.getAssignments("filter[status]=All Project")
        }
        if (this.selectAssignment === "Expired Assignments") {
          this.getAssignments("filter[status]=Expired Assignments")
        }
      }
    },
    handleSeeMore(data) {
      this.$router.push({
        path: "/assignment-proposal",
        params: { id: data.id },
      })
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit("bv::show::modal", this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ""
      this.infoModal.content = ""
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    async getCandidateInvites() {
      axios
        .get(`/candidate_invites`)
        .then((res) => {
          this.upcomingInvites = res.data
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Invite List`,
              icon: "CoffeeIcon",
              variant: "success",
              text: `You have successfully fetched Upcoming Invite List!`,
            },
          })
        })
        .catch((error) => {
          errorResponse(error, this.$router)
        })
    },
    async getCandidateInterviews() {
      axios
        .get(`/candidate_interviews`)
        .then((res) => {
          this.upcomingInterviews = res.data
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Interview List`,
              icon: "CoffeeIcon",
              variant: "success",
              text: `You have successfully fetched Upcoming Interview List!`,
            },
          })
        })
        .catch((error) => {
          errorResponse(error, this.$router)
        })
    },
    async getAssignments(data) {
      axios
        .get(`/assignments/candidate_filter?${data}`)
        .then((res) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Assignment List`,
              icon: "CoffeeIcon",
              variant: "success",
              text: `You have successfully fetched Assignment List!`,
            },
          })

          this.items = res.data
        })
        .catch((error) => {
          errorResponse(error, this.$router)
        })
    },
    async getAssignmentsCount() {
      axios
        .get(`/assignments/candidate_filterCount`)
        .then((res) => {
          this.assignments = [
            {
              color: "primary",
              icon: "ServerIcon",
              statistic: res.data.activeAssignment,
              statisticTitle: "Active Assignments",
              value: "Active Assignments",
            },
            {
              color: "primary",
              icon: "ServerIcon",
              statistic: res.data.assignmentProposal,
              statisticTitle: "Assignments Proposal",
              value: "Assignments Proposal",
            },
            {
              color: "primary",
              icon: "ServerIcon",
              statistic: res.data.interviewStage,
              statisticTitle: "Interview Stage",
              value: "Interview Stage",
            },
            {
              color: "danger",
              icon: "ActivityIcon",
              statistic: res.data.shortlisted,
              statisticTitle: "Shortlisted for",
              value: "Shortlisted",
            },
            {
              color: "danger",
              icon: "ActivityIcon",
              statistic: res.data.waitingForPayment,
              statisticTitle: "Waiting for payment",
              value: "Waiting for Payment",
            },
            {
              color: "danger",
              icon: "ActivityIcon",
              statistic: res.data.completed,
              statisticTitle: "Completed",
              value: "Completed",
            },
            {
              color: "danger",
              icon: "ActivityIcon",
              statistic: res.data.allProject,
              statisticTitle: "All Project",
              value: "All Project",
            },
            {
              color: "warning",
              icon: "AlertOctagonIcon",
              statistic: res.data.expired,
              statisticTitle: "Expired Assignments",
              value: "Expired",
            },
          ]
        })
        .catch((error) => {
          errorResponse(error, this.$router)
        })
    },
  },
}
</script>
